<template>
  <div class="home">
    <b-container>
      <div class="row">
        <div class="col-md-12 m-auto pt-5 mt-5">
          <h3 class="text-center mb-4">Hi, welcome to TwentyEA. We'll let you in in a second.</h3>
          <div class="d-flex justify-content-center pt-5">
            <span class="spinner-border spinner-border-lg position-relative" style="width: 6rem; height: 6rem; color: var(--om-color); " >
            </span>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created() {
    const token = this.$route.query.token;
    if (!token) {
      this.$router.push({name: "login"});
      return;
    }

    localStorage.setItem('token', token)
    axios.get('/user')
      .then(async () => {
        await this.$store.dispatch("auth/user");
        this.$router.push({name: "home"});
      })
      .catch(() => {
        this.$router.push({name: "login"});
      })
  },
};
</script>
